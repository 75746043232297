import React from "react";
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  Paper, 
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Link } from 'react-router-dom'; // Importa Link

const Home = () => {
  return (
    <Box sx={{ flexGrow: 1, px: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', mb: 4 }}>
        Inicio
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Link to="/app/notificaciones/agregar" style={{ textDecoration: 'none' }}>
            <Card sx={{ 
              bgcolor: 'grey.100', 
              boxShadow: 'none', 
              borderRadius: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              transition: '0.3s',
              '&:hover': {
                bgcolor: 'grey.300', // Color más oscuro al hacer hover
              },
            }}>
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <NotificationsActiveIcon sx={{ fontSize: 40, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="subtitle1" component="div" sx={{ color: 'primary.main' }}>
                    Crear notificación
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to="/app/recordatorios/agregar" style={{ textDecoration: 'none' }}>
            <Card sx={{ 
              bgcolor: 'grey.100', 
              boxShadow: 'none', 
              borderRadius: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              transition: '0.3s',
              '&:hover': {
                bgcolor: 'grey.300', // Color más oscuro al hacer hover
              },
            }}>
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <EventNoteIcon sx={{ fontSize: 40, color: 'secondary.main', mb: 2 }} />
                  <Typography variant="subtitle1" component="div" sx={{ color: 'primary.main' }}>
                    Crear recordatorio
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3, mt: 3, bgcolor: 'grey.100', borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'secondary.main', fontWeight: 'bold' }}>
              Recomendaciones
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Recuerda coordinar cuidadosamente las fechas de inicio para el envío de las notificaciones. 
            Esta fecha será el punto de partida para el envío, de acuerdo con los tipos de frecuencia que hayas seleccionado. 
            Ten en cuenta que tanto las notificaciones como los recordatorios no son editables; solo podrás activarlos o desactivarlos según tus necesidades. 
            ¡Asegúrate de tener todo en orden para una comunicación efectiva!
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
