import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  CircularProgress,
  FormHelperText,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RepeticionControl from "./components/RepeticionControl";
import { addNotificacion, getEmpresas, getSucursales, getArticulos } from "../../services/api";
import MessageInput from './components/MessageInput';
import showToast from '../../services/toast';
import Autocomplete from '@mui/material/Autocomplete';

const AddNotificacion = () => {
  const [tipoRepeticion, setTipoRepeticion] = useState("");
  const [repeticion, setRepeticion] = useState([]);
  const [repeticionTemp, setRepeticionTemp] = useState({ dia: "", hora: "" });
  const navigate = useNavigate();
  
  // Estado para manejar empresas y sucursales dinámicamente
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [newNotification, setNewNotification] = useState({
    message: '',
    title: '',
    fechaHoraEnvio: '',
  });

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulos, setSelectedArticulos] = useState([]);

  const [showPromocion, setShowPromocion] = useState(false);

  // Agregar nuevo estado para la fecha de finalización
  const [fechaFinPromocion, setFechaFinPromocion] = useState('');

  // Fetch companies on mount
  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await getEmpresas();
        setCompanies(
          response.data.titulares.map((company) => ({
            id: company.ruc_titular,
            nombre: company.nombre_comercial,
          }))
        );
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoadingCompanies(false);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch branches when a company is selected
  useEffect(() => {
    if (selectedCompany) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await getSucursales(selectedCompany);
          setBranches(
            response.data.sucursales.map((branch) => ({
              id: branch.cod_sucursal,
              nombre: branch.nom_sucursal,
            }))
          );
        } catch (error) {
          console.error("Error fetching branches:", error);
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [selectedCompany]);

  // Fetch artículos on mount
  useEffect(() => {
    const fetchArticulos = async () => {
      try {
        const response = await getArticulos();
        // Transformamos los artículos para incluir un id y nombre más descriptivo
        const articulosFormateados = response.data.lista_articulos.map(articulo => ({
          id: articulo.sku,
          nombre: articulo.nombre_completo,
          sku: articulo.sku,
          precio: articulo.precio,
          imagen: articulo.url,
          categoria: articulo.desc_categoria
        }));
        setArticulos(articulosFormateados);
      } catch (error) {
        console.error("Error fetching artículos:", error);
      }
    };

    fetchArticulos();
  }, []);

  const handleAddRepeticion = () => {
    if (repeticionTemp.dia && repeticionTemp.hora) {
      setRepeticion([...repeticion, { dia: repeticionTemp.dia, hora: repeticionTemp.hora }]);
      setRepeticionTemp({ dia: "", hora: "" });
    }
  };

  const handleDeleteRepeticion = (index) => {
    setRepeticion(repeticion.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    const errors = {};
    
    // Validaciones existentes
    if (!selectedCompany) errors.company = 'La empresa es requerida';
    if (selectedBranches.length === 0) errors.branches = 'Al menos una sucursal es requerida';
    if (!newNotification.title) errors.title = 'El título es requerido';
    if (!newNotification.message) errors.message = 'El mensaje es requerido';

    // Validaciones para la sección de promoción
    if (showPromocion) {
      // Validar fecha de finalización
      if (!fechaFinPromocion) {
        errors.fechaFinPromocion = 'La fecha de finalización de la promoción es requerida';
      }

      // Validar que haya al menos un producto seleccionado
      if (selectedArticulos.length === 0) {
        errors.productos = 'Debe seleccionar al menos un producto';
      }

      // Validar que todos los productos seleccionados tengan precio de descuento
      const productosInvalidos = selectedArticulos.some(articulo => !articulo.precioDescuento);
      if (productosInvalidos) {
        errors.preciosDescuento = 'Todos los productos deben tener un precio de descuento';
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm() ) {
      setOpenConfirmationModal(true);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleBranchChange = (event) => {
    setSelectedBranches(event.target.value);
  };

  const handleConfirmAddNotification = async () => {
    setLoadingConfirm(true);
    const notificationData = {
      all_empresas: false,
      empresa: selectedCompany,
      message_template: newNotification.message,
      sucursales: selectedBranches,
      title: newNotification.title,
      repeat_type: tipoRepeticion,
      send_time: repeticionTemp.hora,
      is_active: true,
      start_datetime: newNotification.fechaHoraEnvio,
      articulos: selectedArticulos.map(articulo => articulo.id),
      promocion: showPromocion ? {
        productos: selectedArticulos.map(articulo => {
          if (!articulo.precioDescuento) {
            console.error(`El artículo ${articulo.nombre} no tiene precio de descuento.`);
            return null;
          }
          return {
            precio: parseInt(articulo.precioDescuento),
            cod_producto: articulo.id.split('.')[0]
          };
        }).filter(Boolean),
        fecha_fin: fechaFinPromocion
      } : null
    };

    if (tipoRepeticion === 'weekly') {
      notificationData.repeat_day_of_week = repeticionTemp.dia;
    } else if (tipoRepeticion === 'monthly') {
      notificationData.repeat_day_of_month = repeticionTemp.dia;
    } else if (tipoRepeticion === 'every_x_days') {
      notificationData.repeat_every_x_days = repeticionTemp.dia;
    }

    console.log('notificationData', notificationData);

    try {
      await addNotificacion(notificationData);
      console.log('Notificación agregada:', notificationData);
      showToast.success('Notificación agregada correctamente.');

      setNewNotification({
        message: '',
        fechaHoraEnvio: '',
        title: '',
      });
      setSelectedCompany('');
      setSelectedBranches([]);
      handleGoBack();
    } catch (error) {
      showToast.error('Ocurrió un error.');
      console.error('Error adding reminder:', error);
    } finally {
      setLoadingConfirm(false);
      setOpenConfirmationModal(false);
    }
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%", display: "grid" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="16px">
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Agregar notificación
          </Typography>
        </Box>
        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Box>
      <form onSubmit={handleSubmit} style={{ marginTop: "24px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="grid-left">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={newNotification.title}
              onChange={(e) => setNewNotification({ ...newNotification, title: e.target.value })}
              style={{ marginBottom: "16px" }}
            />
            <TextField
              label="Fecha y Hora de Envío"
              type="datetime-local"
              variant="outlined"
              fullWidth
              value={newNotification.fechaHoraEnvio}
              onChange={(e) => setNewNotification({ ...newNotification, fechaHoraEnvio: e.target.value })}
              style={{ marginBottom: "16px" }}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth style={{ marginBottom: "16px" }} required error={Boolean(formErrors.company)}>
              <InputLabel>Empresa</InputLabel>
              <Select
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled></MenuItem>
                {loadingCompanies ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.nombre}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>{formErrors.company}</FormHelperText>
            </FormControl>

            {selectedCompany && (
              <FormControl fullWidth style={{ marginBottom: "16px" }} required error={Boolean(formErrors.branches)}>
                <InputLabel>Sucursal(es)</InputLabel>
                <Select multiple value={selectedBranches} onChange={handleBranchChange} displayEmpty required>
                  <MenuItem value="" disabled></MenuItem>
                  {loadingBranches ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.nombre}
                      </MenuItem>
                    ))
                  )}
                </Select>
                <FormHelperText>{formErrors.branches}</FormHelperText>
              </FormControl>
            )}

            {selectedBranches.length > 0 && (
              <Box style={{ marginBottom: "24px" }}>
                <Typography variant="subtitle1">Sucursales seleccionadas:</Typography>
                {selectedBranches.map((branchId) => {
                  const branch = branches.find((branch) => branch.id === branchId);
                  return branch ? (
                    <Chip key={branch.id} label={branch.nombre} style={{ marginRight: "8px" }} />
                  ) : null;
                })}
              </Box>
            )}

            <RepeticionControl
              tipoRepeticion={tipoRepeticion}
              repeticion={repeticion}
              setRepeticion={setRepeticion}
              repeticionTemp={repeticionTemp}
              setRepeticionTemp={setRepeticionTemp}
              handleAddRepeticion={handleAddRepeticion}
              setTipoRepeticion={setTipoRepeticion}
            />

            {repeticion.length > 0 && (
              <Typography variant="subtitle2" style={{ marginTop: "16px" }}>
                Repeticiones
              </Typography>
            )}
            <List>
              {repeticion.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${item.dia}, ${item.hora}`} />
                  <IconButton onClick={() => handleDeleteRepeticion(index)} edge="end">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPromocion}
                    onChange={(e) => setShowPromocion(e.target.checked)}
                  />
                }
                label="Incluir promoción"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <MessageInput
              message={newNotification.message}
              setMessage={(message) => setNewNotification({ ...newNotification, message })}
              helperText={formErrors.message}
              error={Boolean(formErrors.message)}
            />
          </Grid>
        </Grid>
      </form>

      {showPromocion && (
        <>
          <Divider sx={{ my: 4 }} />
          
          <Box sx={{ mt: 0 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Agregar promoción
            </Typography>
            
            <TextField
              label="Fecha de finalización de promoción"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0],
              }}
              onChange={(e) => setFechaFinPromocion(e.target.value)}
              value={fechaFinPromocion}
              fullWidth
              sx={{ mb: 2 }}
              error={Boolean(formErrors.fechaFinPromocion)}
              helperText={formErrors.fechaFinPromocion}
            />

            <Autocomplete
              multiple
              options={articulos}
              getOptionLabel={(option) => `${option.nombre} - ${option.precio}`}
              value={selectedArticulos}
              onChange={(event, newValue) => {
                setSelectedArticulos(newValue);
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    {option.imagen && (
                      <img 
                        src={option.imagen} 
                        alt={option.nombre}
                        style={{ 
                          width: 40, 
                          height: 40, 
                          marginRight: 10,
                          objectFit: 'cover'
                        }}
                      />
                    )}
                    <Box>
                      <Typography variant="body1">{option.nombre}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {option.categoria} - Gs. {parseInt(option.precio).toLocaleString('es-PY')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleccionar artículos"
                  placeholder="Buscar artículos"
                  error={Boolean(formErrors.productos)}
                  helperText={formErrors.productos}
                />
              )}
              disableCloseOnSelect
              filterSelectedOptions
              ChipProps={{ style: { display: 'none' } }}
              sx={{ mb: 2 }}
            />

            {formErrors.preciosDescuento && (
              <Typography color="error" variant="caption" sx={{ mt: -1, mb: 2, display: 'block' }}>
                {formErrors.preciosDescuento}
              </Typography>
            )}

            {selectedArticulos.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Productos seleccionados:
                </Typography>
                {selectedArticulos.map((articulo, index) => (
                  <React.Fragment key={articulo.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                      <Box>
                        <Typography variant="body1">
                          {articulo.nombre}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Precio actual: Gs. {parseInt(articulo.precio).toLocaleString('es-PY')}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label="Precio descuento"
                          variant="outlined"
                          size="small"
                          type="number"
                          sx={{ width: 200, mr: 2 }}
                          onChange={(e) => {
                            const updatedArticulos = selectedArticulos.map(a => 
                              a.id === articulo.id ? { ...a, precioDescuento: e.target.value } : a
                            );
                            setSelectedArticulos(updatedArticulos);
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            setSelectedArticulos(selectedArticulos.filter(a => a.id !== articulo.id));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {index < selectedArticulos.length - 1 && <Divider sx={{ mb: 1 }} />}
                  </React.Fragment>
                ))}
              </Box>
            )}
          </Box>
        </>
      )}

      {/* Confirmation Modal */}
      <Dialog
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
    >
      <DialogTitle>
        <Typography variant="h5">
          Confirmar
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          ¿Está seguro de que desea agregar esta notificacion?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenConfirmationModal(false)}
          className="cancel-button"
          variant="outlined"
          disabled={loadingConfirm}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmAddNotification}
          className="confirm-button"
          variant="contained"
          disabled={loadingConfirm}
        >
          {loadingConfirm ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
    </Container>
  );
};

export default AddNotificacion;
