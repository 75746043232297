import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-3.png";

const Navbar = ({ toggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    navigate('/');
  };

  const handleLogout = () => {
    // Lógica de cierre de sesión
    handleMenuClose();
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleSidebar}>
          <MenuIcon />
        </IconButton>
        <Link
          to="/app/home"
          style={{ textDecoration: "none", color: "inherit" }}>
          <img src={logo} alt="Logo" style={{ height: 40, marginRight: 16 }} />
        </Link>
        <a
  href="https://chat.freshfoodpyonline.com/"
  target="_blank"
  rel="noopener noreferrer"
  style={{ textDecoration: "none", color: "inherit" }}
>
  <Typography
    variant="body1"
    noWrap
    sx={{ display: "flex", alignItems: "center" }}
  >
    Chat
    <OpenInNewIcon sx={{ marginLeft: 1 }} />
  </Typography>
</a>
        {/* <Link
          to="https://chat.freshfoodpyonline.com/"
          style={{ textDecoration: "none", color: "inherit" }}>
          <Typography
            variant="body1"
            noWrap
            sx={{ display: "flex", alignItems: "center" }}>
            Chat
            <OpenInNewIcon sx={{ marginLeft: 1 }} />
          </Typography>
        </Link> */}
        <div style={{ flexGrow: 1 }} />
        <IconButton
          edge="end"
          color="inherit"
          aria-label="profile"
          onClick={handleProfileMenuOpen}
          style={{ marginRight: "2px" }}>
          <Avatar />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              width: "200px",
            },
          }}>
          <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
          <MenuItem onClick={handleMenuClose}>Account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <Button color="inherit" onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
