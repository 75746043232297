// src/components/Sidebar.js
import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { sidebarItems } from "../../config/routes";
import "./sidebar.css";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();

  // Función para determinar si el item es activo
  const isActive = (path) => location.pathname === path || location.pathname.startsWith(path);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isOpen}
      sx={{
        width: isOpen ? 240 : 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isOpen ? 240 : 0,
        },
      }}
      classes={{ paper: "customDrawerPaper" }}
    >
      <List>
        {sidebarItems.map((item) => (
          <ListItem
            button
            component={Link}
            to={item.path}
            key={item.path} // Ensure each item has a unique key
            className={isActive(item.path) ? "active" : ""}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{ className: "MuiListItemText-primary" }}
              sx={{ ml: 0.5 }}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
