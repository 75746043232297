import React, { useState, useEffect } from 'react';
import {
  Container, Button, FormControl, Select, MenuItem, InputLabel, Grid, Box, Typography, Chip, CircularProgress, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getEmpresas, getSucursales, addRecordatorio } from '../../services/api';
import MessageInput from './components/MessageInput';
import showToast from '../../services/toast';

const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export default function AddRecordatorio() {
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [newReminder, setNewReminder] = useState({
    message: '',
    daysBeforeDelivery: 1,
    deadlineTime: '12:00',
    title: '',
  });

  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await getEmpresas();
        setCompanies(response.data.titulares.map(company => ({
          id: company.ruc_titular,
          nombre: company.nombre_comercial
        })));
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setLoadingCompanies(false);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await getSucursales(selectedCompany);
          setBranches(response.data.sucursales.map(branch => ({
            id: branch.cod_sucursal,
            nombre: branch.nom_sucursal,
            diasDeEntrega: branch.entregas.map(entrega => daysOfWeek.indexOf(entrega.dia_semana))
          })));
        } catch (error) {
          console.error('Error fetching branches:', error);
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [selectedCompany]);

  const handleAddReminder = (event) => {
    event.preventDefault();
    if (validateForm() ) {
      setOpenConfirmationModal(true);
    }
  };

  const handleConfirmAddReminder = async () => {
    setLoadingConfirm(true);
    const reminderData = {
      empresa: selectedCompany,
      message_template: newReminder.message,
      sucursales: selectedBranches,
      event_type: "before_delivery",
      time_offset: newReminder.daysBeforeDelivery.toString(),
      send_time: newReminder.deadlineTime,
      title: newReminder.title,
    };

    try {
      await addRecordatorio(reminderData);
      console.log('Recordatorio agregado:', reminderData);
      showToast.success('Recordatorio agregado correctamente.');

      // Clear form after submission
      setNewReminder({
        message: '',
        daysBeforeDelivery: 1,
        deadlineTime: '12:00',
        title: '',
      });
      setSelectedCompany('');
      setSelectedBranches([]);
      handleGoBack();
    } catch (error) {
      showToast.error('Ocurrio un error.');
      console.error('Error adding reminder:', error);
    } finally {
      setLoadingConfirm(false);
      setOpenConfirmationModal(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleBranchChange = (event) => {
    const { value } = event.target;
    setSelectedBranches(typeof value === 'string' ? value.split(',') : value);
  };

  const validateForm = () => {
    const errors = {};
    if (!selectedCompany) errors.company = 'La empresa es requerida';
    if (selectedBranches.length === 0) errors.branches = 'Al menos una sucursal es requerida';
    if (!newReminder.title) errors.title = 'El título es requerido';
    if (!newReminder.message) errors.message = 'El mensaje es requerido';
    if (newReminder.daysBeforeDelivery <= 0) errors.daysBeforeDelivery = 'La cantidad de días debe ser mayor a 0';
    if (!newReminder.deadlineTime) errors.deadlineTime = 'La hora límite es requerida';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="16px">
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>Agregar recordatorio</Typography>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleAddReminder}
        >
          Guardar
        </Button>
      </Box>

      <form onSubmit={handleAddReminder} style={{ marginTop: "24px" }}>
        <Grid container spacing={3}>
          {/* Left Section */}
          <Grid item xs={12} md={6} className="grid-left">
            <TextField
              label="Título"
              variant="outlined"
              fullWidth
              value={newReminder.title}
              onChange={(e) => setNewReminder({ ...newReminder, title: e.target.value })}
              style={{ marginBottom: "16px" }}
              required
              helperText={formErrors.title}
              error={Boolean(formErrors.title)}
            />
            <FormControl fullWidth style={{ marginBottom: "16px" }} required error={Boolean(formErrors.company)}>
              <InputLabel>Empresa</InputLabel>
              <Select
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled></MenuItem>
                {loadingCompanies ? (
                  <MenuItem disabled><CircularProgress size={24} /></MenuItem>
                ) : (
                  companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>{company.nombre}</MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>{formErrors.company}</FormHelperText>
            </FormControl>

            {selectedCompany && (
              <FormControl fullWidth style={{ marginBottom: "16px" }} required error={Boolean(formErrors.branches)}>
                <InputLabel>Sucursal(es)</InputLabel>
                <Select
                  multiple
                  value={selectedBranches}
                  onChange={handleBranchChange}
                  displayEmpty
                  required
                >
                  <MenuItem value="" disabled></MenuItem>
                  {loadingBranches ? (
                    <MenuItem disabled><CircularProgress size={24} /></MenuItem>
                  ) : (
                    branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>{branch.nombre}</MenuItem>
                    ))
                  )}
                </Select>
                <FormHelperText>{formErrors.branches}</FormHelperText>
              </FormControl>
            )}

            {selectedBranches.length > 0 && (
              <Box style={{ marginBottom: "24px" }}>
                <Typography variant="subtitle1">Sucursales seleccionadas:</Typography>
                {selectedBranches.map((branchId) => {
                  const branch = branches.find(branch => branch.id === branchId);
                  return branch ? <Chip key={branch.id} label={branch.nombre} style={{ marginRight: "8px" }} /> : null;
                })}
              </Box>
            )}

            <TextField
              label="Cantidad de días antes de la entrega"
              type="number"
              value={newReminder.daysBeforeDelivery}
              onChange={(e) => setNewReminder({ ...newReminder, daysBeforeDelivery: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              style={{ marginBottom: "16px" }}
              required
              helperText={formErrors.daysBeforeDelivery || "Cantidad de días previos a la entrega"}
              error={Boolean(formErrors.daysBeforeDelivery)}
            />

            <TextField
              label="Hora envío"
              type="time"
              value={newReminder.deadlineTime}
              onChange={(e) => setNewReminder({ ...newReminder, deadlineTime: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              style={{ marginBottom: "16px" }}
              required
              helperText={formErrors.deadlineTime}
              error={Boolean(formErrors.deadlineTime)}
            />
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <MessageInput
              message={newReminder.message}
              setMessage={(message) => setNewReminder({ ...newReminder, message })}
              helperText={formErrors.message}
              error={Boolean(formErrors.message)}
            />
          </Grid>
        </Grid>
      </form>

      {/* Confirmation Modal */}
      <Dialog
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
    >
      <DialogTitle>
        <Typography variant="h5">
          Confirmar
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          ¿Está seguro de que desea agregar este recordatorio?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenConfirmationModal(false)}
          className="cancel-button"
          variant="outlined"
          disabled={loadingConfirm}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmAddReminder}
          className="confirm-button"
          variant="contained"
          disabled={loadingConfirm}
        >
          {loadingConfirm ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
    </Container>
  );
}
