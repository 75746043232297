import { createTheme } from "@mui/material/styles";
import "@fontsource/inter"; // Importa la fuente Inter
import "./fonts.css";

const theme = createTheme({
  typography: {
    fontFamily: "Geist, Arial, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#353535", // Puedes cambiar este color
    },
    secondary: {
      main: "#9DB721", // Puedes cambiar este color
    },
    background: {
      default: "#fafafa", // Puedes cambiar este color
    },
    accent: {
      main: "#5891E7", // Puedes cambiar este color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Aplicar border-radius
          boxShadow: "none", // Eliminar la sombra
          '&:hover': {
            boxShadow: "none", // Eliminar la sombra en hover también
          },
        },
        // Estilo específico para botones primarios
        containedPrimary: {
          backgroundColor: "#353535", // Usa el color primary.main
          color: "#ffffff", // Ajusta el color del texto según lo necesites
          '&:hover': {
            backgroundColor: "#2c2c2c", // Color de fondo en hover (opcional)
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Aplica el border-radius de 8px a todos los inputs
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1em',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: 'none',
    //     },
    //   },
    // },
  },
});

export default theme;
