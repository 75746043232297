import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, Menu, MenuItem as MuiMenuItem, Typography, Box } from '@mui/material';
import { Add as AddIcon, Visibility as EyeIcon } from '@mui/icons-material';
import { getVariables } from '../../../services/api';

const MessageInput = ({ message, setMessage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [preview, setPreview] = useState("");
  const messageRef = useRef(null);

  useEffect(() => {
    const fetchVariables = async () => {
      try {
        const response = await getVariables();
        setDataList(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching variables:', error);
        setDataList([]);
      }
    };

    fetchVariables();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDato = (dato) => {
    const textField = messageRef.current;
    const cursorPosition = textField.selectionStart; // Obtiene la posición del cursor

    const newMessage = message.slice(0, cursorPosition) +
      `{${dato.key}}` + // Inserta el 'key' de la variable
      message.slice(cursorPosition);

    setMessage(newMessage);
    handleClose();
    textField.focus();
    textField.selectionStart = textField.selectionEnd = cursorPosition + dato.key.length + 2;
  };

  const generatePreview = () => {
    let previewMessage = message;

    dataList.forEach(dato => {
      const regex = new RegExp(`{${dato.key}}`, 'g');
      previewMessage = previewMessage.replace(regex, dato.description);
    });

    setPreview(previewMessage);
  };

  return (
    <div>
      <TextField
        label="Mensaje"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        inputRef={messageRef}
        style={{ marginBottom: "16px" }}
      />
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        style={{ marginBottom: "16px" }}
      >
        Agregar Dato
      </Button>
      {message && ( 
        <Button
          onClick={generatePreview}
          variant="outlined"
          color="default"
          style={{ marginBottom: "16px", marginLeft: "8px" }}
          startIcon={<EyeIcon />}
        >
          Preview del mensaje
        </Button>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Array.isArray(dataList) && dataList.length > 0 ? ( 
          dataList.map((dato, index) => (
            <MuiMenuItem key={index} onClick={() => handleSelectDato(dato)}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {dato.key}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {dato.description} 
                </Typography>
              </Box>
            </MuiMenuItem>
          ))
        ) : (
          <MuiMenuItem disabled>
            <Typography variant="body2" color="textSecondary">
              No hay datos disponibles
            </Typography>
          </MuiMenuItem>
        )}
      </Menu>

      {preview && (
        <Box 
          marginTop="16px"
          sx={{
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '16px',
            border: '1px solid #ddd',
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Vista previa del mensaje:
          </Typography>
          <Typography variant="body2" style={{ marginTop: '8px' }}>
            {preview}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default MessageInput;
