// CalendarioDiario.js
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getRecordatorios, getNotifications } from '../../services/api'; // Ajusta la ruta de importación

const localizer = momentLocalizer(moment);

const CalendarioDiario = () => {
  const today = new Date();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener recordatorios
        const recordatoriosResponse = await getRecordatorios();
        const recordatorios = recordatoriosResponse.data;

        // Obtener notificaciones
        const notificationsResponse = await getNotifications();
        const notifications = notificationsResponse.data;

        // Formatear eventos de recordatorios
        const formattedRecordatorios = recordatorios.map(recordatorio => ({
          title: recordatorio.title,
          start: new Date(today.setHours(0, 0, 0)), // Establece la fecha a hoy, hora a 00:00
          end: new Date(today.setHours(0, 0, 0, 0, 0, 0) + (24 * 60 * 60 * 1000)), // Fin a las 23:59 del mismo día
          all_day: true, // Puedes usar esta propiedad para mostrarlo como evento de día completo
        }));

        // Formatear eventos de notificaciones
        const formattedNotifications = notifications.map(notification => ({
          title: notification.title,
          start: new Date(notification.start_datetime), // Convertir el string a fecha
          end: new Date(notification.start_datetime), // Ajusta según tus necesidades
          all_day: false,
        }));

        // Unir ambos eventos
        setEvents([...formattedRecordatorios, ...formattedNotifications]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [today]);

  return (
    <div style={{ height: '600px', overflowY: 'auto' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['day']}
        defaultView="day"
        defaultDate={today}
        min={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 7, 0)} // Hora de inicio a las 7 AM
        style={{ height: '100%', backgroundColor: 'white' }} // Estilo opcional
      />
    </div>
  );
};

export default CalendarioDiario;
