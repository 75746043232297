import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getRecordatorios, getNotifications, getFeriados } from '../../services/api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Notifications as BellIcon, SendOutlined, EventBusy } from '@mui/icons-material';

const localizer = momentLocalizer(moment);

const CalendarioSemanal = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [recordatorios, notificaciones, feriados] = await Promise.all([
          getRecordatorios(),
          getNotifications(),
          getFeriados(),
        ]);

        const combinedEvents = [
          ...(recordatorios.data || []).map((recordatorio) => ({
            id: recordatorio.id,
            title: recordatorio.title,
            start: moment().add(recordatorio.time_offset).toDate(),
            end: moment().add(recordatorio.time_offset).add(1, 'hour').toDate(),
            type: 'recordatorio',
            message_template: recordatorio.message_template,
            empresa: recordatorio.empresa,
            sucursales: recordatorio.sucursales.join(', '),
          })),
          ...(notificaciones.data || []).map((notificacion) => ({
            id: notificacion.id,
            title: notificacion.title,
            start: new Date(notificacion.start_datetime),
            end: moment(new Date(notificacion.start_datetime)).add(1, 'hour').toDate(),
            type: 'notificacion',
            message_template: notificacion.message_template,
            empresa: notificacion.empresa,
            sucursales: notificacion.sucursales.join(', '),
          })),
          ...(feriados.data || []).map((feriado) => ({
            id: feriado.id,
            title: feriado.nombre,
            start: moment(feriado.fecha).toDate(),
            end: moment(feriado.fecha).add(1, 'day').toDate(),
            type: 'feriado',
          })),
        ];

        setEvents(combinedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchData();
  }, []);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  const handleViewDetails = () => {
    if (selectedEvent) {
      const path = selectedEvent.type === 'recordatorio'
        ? `/app/recordatorios/${selectedEvent.id}`
        : selectedEvent.type === 'notificacion'
        ? `/app/notificaciones/${selectedEvent.id}`
        : null;

      if (path) {
        navigate(path);
      }
    }
  };

  const renderEventTitle = (event) => {
    let icon;
    switch (event.type) {
      case 'recordatorio':
        icon = <BellIcon style={{ marginRight: 5 }} />;
        break;
      case 'notificacion':
        icon = <SendOutlined style={{ marginRight: 5 }} />;
        break;
      case 'feriado':
        icon = <EventBusy style={{ marginRight: 5 }} />;
        break;
      default:
        icon = null;
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        {event.title}
      </div>
    );
  };

  return (
    <Grid item xs={6}> {/* Ocupa la mitad del grid */}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        onSelectEvent={handleSelectEvent}
        views={['week']} // Solo muestra la vista semanal
        defaultView="week"
        eventPropGetter={(event) => {
          const backgroundColor = event.type === 'feriado' 
            ? 'red' 
            : event.type === 'recordatorio' 
              ? '#FFD700' 
              : '#00BFFF';
          return { style: { backgroundColor } };
        }}
        components={{
          event: ({ event }) => (
            <span>
              {renderEventTitle(event)}
            </span>
          ),
        }}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ fontWeight: 'bold' }}>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <p>{selectedEvent?.message_template}</p>
          {selectedEvent?.type !== 'feriado' && (
            <>
              <p><strong>Empresa:</strong> {selectedEvent?.empresa}</p>
              <p><strong>Sucursal:</strong> {selectedEvent?.sucursales}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
        <Button 
      onClick={handleCloseDialog} 
      color="primary" 
      variant="text"
    >
      Cerrar
    </Button>
          <Button 
            onClick={handleViewDetails} 
            color="primary" 
            variant="contained" 
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            Ver detalles
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CalendarioSemanal;
