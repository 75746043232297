import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // useLocation para recibir el estado
import {
  Button,
  Container,
  TextField,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { editFeriado } from "../../services/api"; // Importa el servicio de editar feriado
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Importa el ícono para volver
import showToast from '../../services/toast';

const EditFeriado = () => {
  const { state } = useLocation(); // Obtén el estado pasado
  const { feriado } = state; // Desestructura el objeto feriado

  const { id, nombre: initialNombre, fecha: initialFecha } = feriado; // Desestructura los datos iniciales del feriado
  const [nombre, setNombre] = useState(initialNombre || "");
  const [fecha, setFecha] = useState(initialFecha || "");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await editFeriado(id, { nombre, fecha }); // Envía el ID y el objeto modificado
      showToast.success('Feriado editado');
      navigate("/app/feriados"); // Redirige a la lista de feriados después de editar
    } catch (err) {
        showToast.error('Error al actualizar el feriado.');
      //setError("Error al actualizar el feriado.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Editar Feriado
        </Typography>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nombre"
            variant="outlined"
            fullWidth
            margin="normal"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <TextField
            label="Fecha"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Actualizar Feriado
            </Button>
          </Box>
        </form>
      )}
    </Container>
  );
};

export default EditFeriado;
