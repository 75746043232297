import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Box,
  Grid,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Link } from "react-router-dom";
import { getNotifications } from "../../services/api";

const Notificaciones = () => {
  const [filter, setFilter] = useState("");
  const [notificaciones, setNotificaciones] = useState([]); // Inicializa como array vacío
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);

  useEffect(() => {
    const getNotificaciones = async () => {
      try {
        const response = await getNotifications();
        // Asegúrate de que la respuesta es un array antes de establecer el estado
        setNotificaciones(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getNotificaciones();
  }, []);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if (error) setError(true); // Establece el estado de error ;

  const truncateMessage = (message, maxLength) => {
    return message.length > maxLength ? `${message.substring(0, maxLength)}...` : message;
  };

  const renderStatusPill = (isActive) => {
    const pillStyle = {
      display: "inline-block",
      padding: "4px 8px",
      borderRadius: "16px",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: isActive ? "#4caf50" : "#f44336",
    };
    return <span style={pillStyle}>{isActive ? "Activo" : "Inactivo"}</span>;
  };

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  
  // Verifica si notificaciones es un array antes de aplicar filter
  const filteredNotificaciones = Array.isArray(notificaciones)
    ? notificaciones.filter(
        (item) =>
          item.title.toLowerCase().includes(filter.toLowerCase()) ||
          item.empresa.toLowerCase().includes(filter.toLowerCase())
      )
    : [];
    
  const currentNotifications = filteredNotificaciones.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const totalPages = Math.ceil(filteredNotificaciones.length / notificationsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <h1 style={{ marginTop: 0 }}>Notificaciones</h1>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: "16px" }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Filtrar"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} style={{ textAlign: "right" }}>
          <Button variant="contained" color="primary" component={Link} to="/app/notificaciones/agregar">
            Agregar Notificación
          </Button>
        </Grid>
      </Grid>
      { error ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
            <ReportProblemOutlinedIcon style={{ fontSize: 50, color: "gray" }} />
            <Typography variant="h6" color="textSecondary">
              Error al cargar las notificaciones.
            </Typography>
          </Box>
      ) : filteredNotificaciones.length === 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
          <HelpOutlineIcon style={{ fontSize: 50, color: "gray" }} />
          <Typography variant="h6" color="textSecondary">
            No existen notificaciones
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              border: "1px solid lightgray",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Título</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Empresa</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Tipo de Repetición</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Hora de Envío</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Mensaje</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sucursales</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Estado</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentNotifications.map((item, index) => (
                  <TableRow
                    key={index}
                    className="table-row-hover"
                    sx={{
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.all_empresas ? "Todas las empresas" : item.empresa}</TableCell>
                    <TableCell>{item.repeat_type.charAt(0).toUpperCase() + item.repeat_type.slice(1)}</TableCell>
                    <TableCell>{item.send_time}</TableCell>
                    <TableCell>{truncateMessage(item.message_template, 50)}</TableCell>
                    <TableCell>{item.sucursales.join(", ")}</TableCell>
                    <TableCell>{renderStatusPill(item.is_active)}</TableCell>
                    <TableCell>
                      <IconButton component={Link} to={`/app/notificaciones/${item.id}`}>
                        <ChevronRightIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Sección de paginación */}
          {filteredNotificaciones.length > notificationsPerPage && (
            <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2}>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                Anterior
              </Button>
              <Typography style={{ margin: "0 16px" }}>
                Página {currentPage} de {totalPages}
              </Typography>
              <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Siguiente
              </Button>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default Notificaciones;
